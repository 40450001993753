<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="newsHeadTitle" :headImg="newsHeadImg" style="margin-top:96px;"></navigation>
		
		<div class="newsPc">
			<!-- 新闻-数据 -->
			<el-row>
				<el-col class="newsPublicityPc-dataall" :span="8" v-for="(item,id) in ListData" :key="id">
					<div @click="newsDetails(item)" class="news-data">
						<el-row class="news-data-extend">
							<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsdata-img"></img>
							<el-image class="newsdata-coverageimg" src="/home/newCoverage.png"></el-image>
							<p class="newsdata-threeLogoimg"></p>
							<div class="newsdata-titleMessage">
								<p class="newsdata-title">{{item.title}}</p>
								<p class="newsdata-date">
									<span>{{item.month}}</span>
									<span>{{item.time}},</span>
									<span>{{item.year}}</span>
								</p>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
			<!-- 按钮 -->
			<el-row class="newsPublicityPcbuttonbox">
				<el-col :span="8" :offset="8" v-if="loading">
					<el-button class="newsPublicityPcbutton" round @click="load">Load More</el-button>
				</el-col>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'
	
// 头部组件
import headLogo from '@/components/headLogo.vue'
// 头部导航
import navigation from '@/components/navigation.vue'
// 底部导航
import bottomNavigation from '@/components/bottomNavigation.vue'
// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
export default
{
	data()
	{
		return{
			// 新闻头部导航标题
			newsHeadTitle:'WHAT`S ON',
			// 新闻头部导航图片
			newsHeadImg:'/index/list.png',
			// 导航值
			navigationvalue:false,
			
			// 是否展示
			isshow:true,
			
			// 默认显示条数
			count:12,
			// 加载按钮
			loading:true,
			// 收起按钮
			newList:[],
			
			newsNotLoadImage: "/PCimage/370_424.jpg", //新闻图片未加载出来
		}
	},
	components:
	{
		headLogo,
		navigation,
		bottomNavigation,
		meauDialog
	},
	computed:
	{
		// 计算属性
		ListData()
		{
			let data=[];
			if(this.newList.length>12)
			{
				data=this.newList.slice(0,this.count)
				return data;
			}
			else
			{
				data=this.newList;
				this.loading=false;
				return data;
			}
		}
	},
	created()
	{
		this.getNewsList()
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		
		// 获取NEWS新闻数据
		getNewsList()
		{
			selectContentListById(
			{
				chnanelType: 1,
				isIndex: 0,
				moduleType: 'MODULE_WHAT'
			})
			.then((response)=>
			{
				if(response.data.data=='')
				{
					this.$router.push(
					{
						path:'/error',
						item:2,
					})
				}
				else
				{
					this.newList=response.data.data;
					this.loading=true;
					this.newList.map((v)=>
					{
						this.time(v)
					})
				}
			})
		},
		
		// 加载更多
		load()
		{
			this.count+=12;
			if(this.count>=this.newList.length)
			{
				this.loading=false
			}
		},
		newsDetails(item)
		{
			localStorage.setItem('Details',JSON.stringify(item));
			this.$router.push(
			{
				path:'/noticesDetails',
				query:
				{
					item:item,
					headTitle:'WHAT`S ON',
					headImg:'/index/list.png',
				}
			})
		},
		
		// 图片加载完成时触发
		onLoadImg(imgSrc) 
		{
			return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
		},
		
		// 时间
		time(v)
		{
			v.pulish=v.publish.split('-')
			if(v.pulish[1]=='01')
			{
				v.pulish[1]='JAN'
			}
			else if(v.pulish[1]=='02')
			{
				v.pulish[1]='FEB'
			}
			else if(v.pulish[1]=='03')
			{
				v.pulish[1]='MAR'
			}
			else if(v.pulish[1]=='04')
			{
				v.pulish[1]='APR'
			}
			else if(v.pulish[1]=='05')
			{
				v.pulish[1]='MAY'
			}
			else if(v.pulish[1]=='06')
			{
				v.pulish[1]='JUN'
			}
			else if(v.pulish[1]=='07')
			{
				v.pulish[1]='JUL'
			}
			else if(v.pulish[1]=='08')
			{
				v.pulish[1]='AUG'
			}
			else if(v.pulish[1]=='09')
			{
				v.pulish[1]='SEP'
			}
			else if(v.pulish[1]=='10')
			{
				v.pulish[1]='OCT'
			}
			else if(v.pulish[1]=='11')
			{
				v.pulish[1]='NOV'
			}
			else if(v.pulish[1]=='12')
			{
				v.pulish[1]='DEC'
			}
			v.year=v.pulish[0];
			v.month=v.pulish[1];
			v.time=v.pulish[2];
		},
	}
}
</script>

<style scoped>
	/* 封装css */
	@import '../assets/newsBox.css';
	.newsPublicityPcbuttonbox{
		width: 100%;
		height: 40px;
		margin-top: 50px;
	}
	.newsPublicityPcbutton{
		background-color: #FCD002;
		color: #505050;
		width: 400px;
		height: 50px;
		border-radius: 25px;
		font-size: 24px;
	}
	.newsPublicityPcnobutton{
		width: 100%;
		height: 40px;
		background-color: #ccc;
		color: #505050;
		line-height: 20px;
	}
	.news-data{
		margin-top: 0px;
	}
</style>